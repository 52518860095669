import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { formatMessage, FormattedMessage } from '../../translations'

import { store as articleStore } from '..'
import { censhareStore } from '../../censhare/reducer'
import { deepGet } from '../../shared/obj'
import Dropdown, {
  DropdownContainer,
  Entry,
  DropdownOpener,
  Separator
} from '../../shared/components/Dropdown'
import { dispatcher } from '../../shared/lib/command'
import { filter } from '../../shared/obj'

import GenevaDropdownOpener from '../../ui/components/GenevaDropdownOpener'

import { confirmDelete, articleSecurity, addLanguage, articleUsage } from '../containers/dialogs'
import { censhareRefresh } from '../../censhare/containers/dialogs'
import metaDialog from '../containers/MetaDialog'

import { ArticleLanguageEntry } from './ArticleLanguageEntry'

const ObservedEntry = observer((props) => {

  const { entry, id, onClick } = props

  return (<Entry
    {...entry.state}
    identifier={id}
    onClick={onClick}
  >
    {entry.name}
  </Entry>)

})

@dispatcher
@observer
class ArticleCMToolbar extends Component {

  static propTypes = {
    className: PropTypes.string,
    ui: PropTypes.object.isRequired,
    article: PropTypes.object.isRequired,
    project: PropTypes.object,
    disabled: PropTypes.bool,
  }

  isValidEntry(entry) {

    if (entry === '-') {
      return true
    }

    if (!entry.name) {
      console.warn('ArticleCMToolbar: A custom entry HAS to have a name property of type string')
      return false
    }

    if (!('handleClick' in entry)) {
      // eslint-disable-next-line max-len
      console.warn('ArticleCMToolbar: A custom entry HAS to have an handleClick property of type function')
      return false
    }

    return true
  }

  @autobind
  handleEntryClick({ target }) {
    // target.identifier should be a string divided by a |,
    //  first is the group and second is the option
    const group = target.identifier.split('|')
    if (group[0] === '') {
      group[0] = formatMessage({ id: 'article.options' })
    }
    // find the entry by group and name
    const entry = this.props.ui.articleContextTools.find((el) => {
      return (el.groupName === group[0] && el.name === group[1])
    })

    if (entry && entry.handleClick) {
      entry.handleClick()
      this.props.article.current.save()
    }

    // Loops through all the query state functions if given
    // This adds the possibility to update the state of other entries
    // which might rely on it
    this.props.ui.articleContextTools.forEach((el) => {
      if (el.handleAllQueryStates) {
        el.handleAllQueryStates(el.state)
      }
    })

    if (entry && entry.handleQueryState) {
      entry.handleQueryState(entry.state)
    }
  }

  @autobind
  handleOpenMeta() {
    metaDialog({
      data: {
        metaSettings: this.props.ui.articleMetaSettings,
        article: this.props.article.currentEditable,
        projectStore: this.props.project,
      }
    })
      .then((result) => {
        console.log('Metadialog closed successfully', result)
      })
      .catch((err) => {
      // Catch the cancel event from the dialog
        console.log(err)
      })
  }

  // Keep for CM
  @autobind
  handleDeleteArticle() {

    const article = this.props.article.currentEditable
    const articleId = article.id

    confirmDelete({
      article
    }).then(() => {

      articleStore.actionDestroyItem(articleId)

    })

  }

  @autobind
  handleOpenArticleSecurityDialog() {

    const article = this.props.article.currentEditable

    const filtered = filter(
      article || {},
      [
        'releasePublicationLockAt',
        'publicationNotAllowed'
      ]
    )
    articleSecurity({
      filtered
    }).then((result) => {

      article.set({
        ...result.data
      })
      article.save()
      // TODO: Catch error and remove data
      // .catch(() => {
      // })

    }).catch((err) => {
      // Catch the cancel event from the dialog
      console.log(err)
    })
  }

  @autobind
  handleAddLanguage() {

    const article = this.props.article.current

    addLanguage({
      article,
      storeWithLangInfo: this.props.project
    }).then((dialogResult) => {

      articleStore.createArticlesInLanguage(
        article.id,
        dialogResult.toCreate,
        dialogResult.toOpen,
        this.props.project.current.id
      ).then((id) => {
        if ((article.id !== id) && (id !== 0)) {

          // Project Id is the current id, since we don't want to change the base project and page
          this.context.dispatch(
            this.props.commands.EditItemCommand,
            {
              router: this.props.router,
              type: 'article',
              id,
              langId: dialogResult.toOpen,
              projectId: this.props.project.current.id
            }
          )
        }
      })

    }).catch((err) => {
      // TODO: catch error
      // Catches cancel Dialog

      console.log(err)
    })
  }

  @autobind
  handleSelectLanguage(article, languageKey) {

    let articleId = article.id // fallback value

    // find the id to swap to
    article.languages.forEach((lang) => {
      if (lang.createdIso === languageKey) {
        articleId = lang.id
      }
    })

    // pass the project id to avoid having the command to autoswitch it
    const projectId = deepGet(this, 'props.project.current.id') || deepGet(this, 'props.params.projectId')
    this.context.dispatch(
      this.props.commands.EditItemCommand,
      {
        router: this.props.router,
        type: 'article',
        id: articleId,
        langId: languageKey,
        projectId
      }
    )
  }

  @autobind
  handleArticleUsage() {

    const article = this.props.article.current

    articleUsage({
      article
    }).then((dialogResult) => {

      this.context.dispatch(
        this.props.commands.EditItemCommand,
        {
          router: this.props.router,
          type: 'page',
          ...dialogResult
        }
      )

    }).catch((err) => {
      // Catch the cancel event from the dialog
      console.log(err)
    })
  }

  @autobind
  handleOpenCenshareRefresh() {
    const article = this.props.article.current

    censhareRefresh({
      model: article,
      censhareStore
    }).then((dialogResult) => {

    }).catch((err) => {
      // Catch the cancel event from the dialog
      console.log(err)
    })
  }

  renderCustomContextTools() {
    const { ui: { articleContextTools } } = this.props
    const defaultGroup = formatMessage({ id: 'article.options' })

    const groups = {}
    // builds an object with arrays for each group name
    articleContextTools.forEach((option) => {
      // if no name is given, use the default/backward-compatable name
      if (!option.groupName) {
        option.groupName = defaultGroup
      }
      if (!groups[option.groupName]) {
        groups[option.groupName] = []
      }
      groups[option.groupName].push(option)
      groups[option.groupName].key = option.groupName

    })
    return Object.keys(groups).map((groupName) => {
      return this.renderCustomContextToolsGroup(groups[groupName])
    })
  }

  renderCustomContextToolsGroup(group) {
    return (<Entry>

      <DropdownOpener key={group[0].groupName}>
        { group[0].groupName }
      </DropdownOpener>

      <Dropdown>

        {group.map(entry => (entry === '-'

          ? <Separator />

          : <ObservedEntry
            key={`article-context-tools-${entry.name}`}
            entry={entry}
            onClick={this.handleEntryClick}
            id={`${entry.groupName}|${entry.name}`}
          />

        ))}

      </Dropdown>

    </Entry>)
  }

  render() {

    let {
      ui: { articleContextTools }
    } = this.props

    const {
      disabled,
      ui: { articleMetaSettings },
      article,
      project
    } = this.props

    const usage = deepGet(this.props.article, 'current.usage') || []
    const showUsage = (usage.length > 0)
    const hasCenshare = project.current && project.current.hasCenshare

    articleContextTools = (articleContextTools || []).filter(this.isValidEntry)
    return (<div
      className={classNames(this.props.className, 'toolbar', 'v-align')}
    >
      <DropdownContainer>

        <GenevaDropdownOpener
          clickToClose
          disabled={disabled}
          caret={false}
          arrow
        >
          <FormattedMessage id="article.article" />&nbsp;
        </GenevaDropdownOpener>
        <Dropdown>

          <Entry
            onClick={this.handleOpenArticleSecurityDialog}
          >
            <FormattedMessage id="article.permissions" />...
          </Entry>

          {articleContextTools && articleContextTools.length
            ? this.renderCustomContextTools()
            : null
          }

          {articleMetaSettings
            ? <Entry
              onClick={this.handleOpenMeta}
            >
              <FormattedMessage id="article.meta" />...
            </Entry>
            : null
          }

          <Separator />
          <Entry>
            <DropdownOpener
              arrow
            >
              <FormattedMessage id="article.select-language" />
            </DropdownOpener>

            <Dropdown>
              {
                article.hasCurrent && article.current.isEditable
                  ? article.current.languages.map((item) => {
                    const current = item.createdIso === article.current.createdIso
                    return (
                      <ArticleLanguageEntry
                        article={article.current}
                        checked={current}
                        key={item.createdIso}
                        languageKey={item.createdIso}
                        onClick={this.handleSelectLanguage}
                      >
                        {item.createdIso}
                      </ArticleLanguageEntry>
                    )
                  })
                  : null
              }
            </Dropdown>
          </Entry>

          <Entry
            onClick={this.handleAddLanguage}
          >
            <FormattedMessage id="article.add-language" />...
          </Entry>


          <div className={classNames({ hidden: !hasCenshare })}>
            <Separator />
            <Entry
              onClick={this.handleOpenCenshareRefresh}
            >
              <FormattedMessage id="page.censhare-refresh" />...
            </Entry>
          </div>

          <div className={classNames({ hidden: !showUsage })}>
            <Separator />
            <Entry
              onClick={this.handleArticleUsage}
            >
              <FormattedMessage id="article.usage" />
            </Entry>
          </div>

        </Dropdown>

      </DropdownContainer>
    </div>)

  }
}

export default ArticleCMToolbar
