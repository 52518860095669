
import * as PropTypes from 'prop-types'

import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import {
  FormattedMessage,
  FormattedHTMLMessage,
} from '../../../translations'
import Icon from '../../../shared/icons'
import {
  URLDropIndicator as BaseURLDropIndicator,
  FileDropIndicator as BaseFileDropIndicator,
} from '../../../shared/components/DropIndicator'
import uploadDialog from '../../../shared/containers/UploadDialog'
import GenevaButton from '../../../ui/components/GenevaButton'

const css = /* typeof window === 'undefined' ? {} : */ require('../../styles.scss')

/**
 * This is a proxied URLDropIndicator with the purpose of moving the mobx
 * binding as deep as possible, so only the smallest portion has to be
 * re-rendered.
 * It wraps the shared/components~DropIndictor.URLDropIndicator
 */
const URLDropIndicator = observer(props => (
  <BaseURLDropIndicator
    {...props}
    isValidDropTarget={props.ui.hasActiveURLDrag}
  >
    {/* <FormattedMessage id="image.drop-url-here" /> */}
  </BaseURLDropIndicator>
))

URLDropIndicator.propTypes = {
  ui: PropTypes.object,
}

/**
 * This is a proxied URLDropIndicator with the purpose of moving the mobx
 * binding as deep as possible, so only the smallest portion has to be
 * re-rendered.
 * It wraps the shared/components~DropIndictor.FileDropIndicator
 */
const FileDropIndicator = observer(props => (
  <BaseFileDropIndicator
    strategyProps={{
      readFile: true,
      onProgress: () => {
        // eslint-disable-next-line no-undef
        console.log(arguments)
      },
    }}
    {...props}
    isValidDropTarget={props.ui.hasActiveFileDrag}
  >
    <FormattedMessage id="image.drop-file-here" />
  </BaseFileDropIndicator>
))

FileDropIndicator.propTypes = {
  ui: PropTypes.object,
}

class UploadTools extends Component {
  static propTypes = {
    style: PropTypes.object,

    spec: PropTypes.object,
    onBeforeItemChange: PropTypes.func,
    onChangeImage: PropTypes.func,
    onChangeVideo: PropTypes.func,
    onChangeFile: PropTypes.func,
    onImportFromMediaManager: PropTypes.func,
    onImportFromBynder: PropTypes.func,
    onVideoImportFromBynder: PropTypes.func,
    onFileImportFromBynder: PropTypes.func,
    onErrorImage: PropTypes.func,
    onErrorFile: PropTypes.func,

    justButton: PropTypes.bool,

    sourceType: PropTypes.string,
    file: PropTypes.object,
    url: PropTypes.string,
    value: PropTypes.string,

    ui: PropTypes.object,
  };

  static defaultProps = {
    spec: {
      mediaType: '*',
    },
  };

  constructor(props) {
    super(props)

    this.state = {
      sizes: this.getSizes(),
      fileDragOver: false,
      urlDragOver: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.spec !== this.props.spec) {
      this.setState({
        sizes: this.getSizes(),
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      ['style', 'sourceType', 'file', 'url'].reduce(
        (memo, prop) => memo || this.props[prop] !== nextProps[prop],
        false
      )
      || ['sizes'].reduce(
        (memo, prop) => memo || this.state[prop] !== nextState[prop],
        false
      )
    )
  }

  getSizes() {
    const spec = this.props.spec
    if (!spec.minWidth && !spec.minHeight) {
      return null
    }
    return {
      width: spec.minWidth || null,
      height: spec.minHeight || null,
    }
  }

  getMediaConfig(spec, type = 'image') {
    const mediaSpec = spec[type] || {}
    const mediaProps = this.props

    return {
      multiple:
        ('multiple' in mediaSpec ? mediaSpec.multiple : spec.multiple)
        || mediaProps.multiple,
      accept:
        ('accept' in mediaSpec ? mediaSpec.accept : spec.accept)
        || mediaProps.accept,
      label: 'label' in mediaSpec ? mediaSpec.label : null,
      constraintsText:
        ('constraintsText' in mediaSpec ? mediaSpec.constraintsText : null)
        || mediaProps.constraintsText,
    }
  }

  @autobind
  getSpecificHandleFunctions(type = 'image', tab) {
    switch (type) {
      case 'image':
        return {
          handleChange: this.handleChangeImage,
          handleProgress: this.handleProgressImage,
          handleError: this.handleError,
        }
      case 'video':
        return {
          handleChange: this.handleChangeVideo,
          handleProgress: this.handleProgressVideo,
        }
      case 'fromMediamanager':
        return {
          handleChange: this.handleFromMediamanager,
        }
      case 'fromBynder':
        if (tab === 'video') {
          return {
            handleChange: this.handleVideoFromBynder
          }
        }
        if (tab === 'file') {
          return {
            handleChange: this.handleFileFromBynder
          }
        }
        return {
          handleChange: this.handleFromBynder
        }
      case 'file':
        return {
          handleChange: this.handleChangeFile,
          handleProgress: this.handleProgressFile,
          handleError: this.handleErrorFile,
        }
      default:
        return {
          handleChange: this.handleChangeFile,
          handleProgress: this.handleProgressFile,
        }
    }
  }

  isBusy() {
    return !this.props.justButton && this.hasValue()
  }

  hasValue() {
    return (
      (this.props.sourceType === 'file' && this.props.file)
      || (this.props.sourceType === 'url' && this.props.url)
    )
  }

  isMediaType(type) {
    return (
      !this.props.spec.mediaType
      || this.props.spec.mediaType === type
      || this.props.spec.mediaType === '*'
    )
  }

  @autobind
  handlePreventFocus(event) {
    event.preventDefault()
  }

  @autobind
  handleChangeImage(files) {
    if (this.props.onChangeImage) {
      this.props.onChangeImage(files)
    }
  }

  @autobind
  handleChangeVideo(files) {
    if (this.props.onChangeVideo) {
      this.props.onChangeVideo(files)
    }
  }

  @autobind
  handleChangeFile(files) {
    if (this.props.onChangeFile) {
      this.props.onChangeFile(files)
    }
  }

  @autobind
  handleError(err) {
    if (this.props.onErrorImage) {
      this.props.onErrorImage(err)
    }
  }

  @autobind
  handleErrorFile(err) {
    if (this.props.onErrorFile) {
      this.props.onErrorFile(err)
    }
  }

  @autobind
  handleFromMediamanager() {
    if (this.props.onImportFromMediaManager) {
      this.props.onImportFromMediaManager()
    }
  }

  @autobind
  handleFromBynder() {
    if (this.props.onImportFromBynder) {
      this.props.onImportFromBynder()
    }
  }

  @autobind
  handleVideoFromBynder() {
    if (this.props.onVideoImportFromBynder) {
      this.props.onVideoImportFromBynder()
    }
  }

  @autobind
  handleFileFromBynder() {
    if (this.props.onFileImportFromBynder) {
      this.props.onFileImportFromBynder()
    }
  }

  @autobind
  handleFileDragHover({ target }) {
    this.setState({
      fileDragOver: target.dragOver,
    })
  }

  @autobind
  handleURLDrop({ target }) {
    if (target.url && this.props.onChangeImage) {
      this.props.onChangeImage(target.url, target.censhareData)
    }
  }

  @autobind
  handleFileDrop({ target }) {
    if (
      this.props.onChangeImage
      && target
      && target.files
      && Array.isArray(target.files)
    ) {
      if (this.isMediaType('file')) {
        this.props.onChangeFile(target.files)
      }
      else {
        // TODO: handle videos
        this.props.onChangeImage(target.files)
      }
    }
  }

  @autobind
  handleUpload() {
    const { spec, onBeforeItemChange } = this.props

    const config = this.getMediaConfig(spec, spec.mediaType)

    uploadDialog({
      config,
      mediaType: spec.mediaType,
      getSpecificHandleFunctions: this.getSpecificHandleFunctions,
      onBeforeItemChange,
      handlePreventFocus: this.handlePreventFocus,
      handleFileDragHover: this.handleFileDragHover,
    }).then((result) => {
      // url handling if image or file
      if (result.type === 'image' || result.type === 'file') {
        this.getSpecificHandleFunctions(result.type, result.tab).handleChange(result.url)
      }
      else {
        this.getSpecificHandleFunctions(result.type, result.tab).handleChange(result)
      }
    })
  }

  renderTools() {
    return (
      <div
        className={classNames(
          css.mediaTools,
          'grid-block',
          'align-center',
          'shrink',
          // for overwriting in templstes:
          'media-tools'
        )}
      >
        <GenevaButton
          className="geneva-small-button"
          onClick={this.handleUpload}
        >
          <Icon name="ion-ios-plus-empty" />
        </GenevaButton>
      </div>
    )
  }

  renderConstraintsInfo() {
    const { spec } = this.props

    // bail out if no constraint text should be rendered
    if (spec.hideConstraints) {
      return null
    }

    const messages = []

    if (this.isMediaType('image')) {
      const imageConfig = this.getMediaConfig(spec, 'image')
      if (imageConfig.constraintsText) {
        messages.push(imageConfig.constraintsText)
      }
    }

    if (this.isMediaType('video')) {
      const videoConfig = this.getMediaConfig(spec, 'video')
      if (videoConfig.constraintsText) {
        messages.push(videoConfig.constraintsText)
      }
    }

    if (this.isMediaType('image') || this.isMediaType('video')) {
      // TODO: render type specific info
      if (!this.state.sizes) {
        messages.push(<FormattedMessage id="media.no-constraints" />)
      }
      else if (!this.state.sizes.width && this.state.sizes.height) {
        messages.push(
          <FormattedMessage
            id="media.constraints-min-height"
            values={this.state.sizes}
          />
        )
      }
      else if (!this.state.sizes.height && this.state.sizes.width) {
        messages.push(
          <FormattedMessage
            id="media.constraints-min-width"
            values={this.state.sizes}
          />
        )
      }
      else {
        messages.push(
          <FormattedHTMLMessage
            id="media.constraints"
            values={this.state.sizes}
          />
        )
      }
    }

    if (this.isMediaType('file')) {
      const fileConfig = this.getMediaConfig(spec, 'file')
      if (fileConfig.constraintsText) {
        messages.push(fileConfig.constraintsText)
      }
    }

    return React.Children.toArray(messages)
  }

  renderMandatoryMessage() {
    const { spec } = this.props

    if (spec && spec.isMandatory === 'true') {
      return (
        <div className="mandatory">
          <FormattedHTMLMessage id="editor.mandatory" />
        </div>
      )
    }
    return null
  }

  renderState() {
    if (!(this.props.sourceType && this.props[this.props.sourceType])) {
      return null
    }

    const item = this.props[this.props.sourceType]

    const items = [
      {
        content: this.props.value,

        name:
          this.props.sourceType === 'file'
            ? item.name
            : item.split('/').pop().split('?').splice(0, 1),
      },
    ]

    return (
      <ul className={classNames('no-bullet', 'align-center', 'shrink')}>
        {items.map(itm => (
          <li key={itm.name} className={css.mediaPreview}>
            {this.props.spec.mediaType === 'img' ? (
              <img role="presentation" src={itm.content} />
            ) : null}
            {itm.name}
          </li>
        ))}
      </ul>
    )
  }

  render() {
    const busy = this.isBusy()

    return (
      <div
        style={this.props.style}
        className={classNames(css.mediaUploadHelper, 'media-upload-helper')}
      >
        <URLDropIndicator
          className={css.mediaDragOverlay}
          disabled={!!busy}
          ui={this.props.ui}
          onDrop={this.handleURLDrop}
        />

        <FileDropIndicator
          className={css.mediaDragOverlay}
          disabled={!!busy}
          ui={this.props.ui}
          onDrop={this.handleFileDrop}
          strategyProps={{
            readFile: !this.isMediaType('file'),
            processableFileTypes: ['*'],
            onProgress: this.handleProgressFile,
          }}
        />

        <div
          className={classNames(
            css.mediaUploadHelperContainer,
            'grid-block',
            'vertical',
            'v-align',
            'align-center'
          )}
        >
          <div
            className={classNames(
              css.mediaConstraintsText,
              'grid-block',
              'align-center',
              'shrink',
              'text-center'
            )}
          >
            {this.renderConstraintsInfo()}
            {this.renderMandatoryMessage()}
          </div>

          {busy ? this.renderState() : this.renderTools()}
        </div>
      </div>
    )
  }
}
export { UploadTools }

export default UploadTools
