import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'

import { filter } from '../../../shared/obj'

export default function connectEditorToContext(Editor, parentProps) {
  if (!parentProps.articlePlaceholders) {
    // eslint-disable-next-line max-len
    throw new Error(
      "`connectEditorToContext` requires an articlePlaceholders Object in it's parentProps"
    )
  }

  const ALLOWED_ATTRS = [
    'itemtype',
    'itemscope',
    'itemprop',
    'className',
    'aria-describedby',
    'aria-description',
    'aria-details',
    'aria-disabled',
    'aria-hidden',
    'aria-label',
    'aria-labelledby',
    'aria-level',
    'aria-modal'
  ]

  @observer
  class PMContextConnectedEditor extends Component {
    static propTypes = {
      pid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

      value: PropTypes.string,

      maxLen: PropTypes.number,
      tagName: PropTypes.string,
      showWhenEmpty: PropTypes.bool,
      className: PropTypes.string,
    };

    render() {
      // eslint-disable-next-line no-unused-vars
      const env = toJS(parentProps.env)

      const value
        = parentProps.articlePlaceholders.get(this.props.pid) || this.props.value
      const TagName = this.props.tagName || 'div'

      const innerHTML = { __html: value || '' }
      const editorProps = {
        ...filter(this.props, ALLOWED_ATTRS),
      }

      if (value || this.props.showWhenEmpty) {
        return (
          <TagName
            {...editorProps}
            dangerouslySetInnerHTML={innerHTML}
          />
        )
      }

      return null
    }
  }

  return PMContextConnectedEditor
}
