import Command from '../../shared/command'

/**
 * Removes the connection of a placeholder to censhare.
 * Keeps the content and makes the placeholder editable again
 * @extends shared~Command
 */
export default
class DisconnectArticlePlaceholderCommand
  extends Command {

  constructor(placeholderData) {

    super(placeholderData)

    this.data = placeholderData

  }

  /**
   * Validates the data in this command.
   * @param {Object} data - The data of the command
   * @private
   */
  validate() {
    // TODO
  }

  exec() {
    const value = this.data.article.phAccessor.get(this.data.pid)
    return this.data.article.phAccessor.set(
      this.data.pid,
      'value',
      {
        type: 'text',
        source: null,
        value,
      }
    )
  }
}
