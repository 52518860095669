import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { confirmable } from 'react-confirm'
import { autobind } from 'core-decorators'
import { observer } from 'mobx-react'
import { formatMessage, FormattedMessage } from '../../../translations'
import { Dialog } from '../../../shared/components/Dialog'

const css = /* typeof window === 'undefined' ? {} : */require('../../styles.scss')

@confirmable
@observer
class MetaTags extends Component {

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.shape({
      titleText: PropTypes.string,
      altText: PropTypes.string
    })
  }

  constructor(props) {
    super(props)

    this.state = {
      titleText: this.props.options.titleText || '',
      altText: this.props.options.altText || ''
    }
  }

  @autobind
  handleTitleChange({ target }) {
    this.setState({
      titleText: target.value
    })
  }

  @autobind
  handleAltChange({ target }) {
    this.setState({
      altText: target.value
    })
  }

  @autobind
  handleCommit(e) {
    e.preventDefault()
    e.stopPropagation()

    return (
      this.props.proceed({
        titleText: this.state.titleText,
        altText: this.state.altText
      })
    )
  }


  render() {

    const {
      show,
    } = this.props

    return (<Dialog
      className={css.metaTagsDialog}
      overlayClassName={css.tableDialogOverlay}
      isOpen={show}
      onRequestClose={this.handleRequestClose}
      title={formatMessage({ id:
        'image.meta-tags.title'
      })}
      id="metaTagsDialog"
      onCommit={this.handleCommit}
    >
      <div className="title-input-block">
        <label htmlFor="titleTag">
          <FormattedMessage id="image.meta-tags.title-tag" />
        </label>
        <input
          id="titleTag"
          type="text"
          onChange={this.handleTitleChange}
          name="titleTag"
          defaultValue={this.state.titleText}
        />

      </div>
      <div className="alt-input-block">
        <label htmlFor="altTag">
          <FormattedMessage id="image.meta-tags.alt-tag" />
        </label>
        <input
          id="altTag"
          type="text"
          onChange={this.handleAltChange}
          name="altTag"
          defaultValue={this.state.altText}
        />
      </div>
    </Dialog>)
  }
}

export default MetaTags
