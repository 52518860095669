import { observable, computed, action, toJS } from 'mobx'
import Communicator from '../Communicator'
import { store as uiStore } from '../ui'
import { useProjectFilter } from '../config'
import { inspector } from '../shared/decorators/inspector'
import { overloadable } from '../shared/decorators/overload'
import { deepGet } from '../shared/obj'
import { addToCollection, i18n } from '../shared/utils'
import { Store } from '../shared/store'
import communicate from '../shared/decorators/communicator'
import { paginate, filterable, apiClient } from '../shared/api'
import { CircularDependencyFreeStore } from '../CircularDependencyFreeStore'
import Article from './model'
import { formatMessage } from '../translations'

const defaultVals = {
  page: 0,
  hasMoreItems: null,
  collection: [],
  modifiedCollection: [],
  filter: {},
  sorting: {},
  loading: null,
  total: 0,
  filterTotal: null, // can't set to 0 since 0 is a meaningful result that we need to react to
  filterMeta: {},
}

@paginate
@overloadable
@filterable
@inspector('save', 'article.saving')
@apiClient
@communicate(Communicator.getInstance())
export default class ArticleStore extends Store {
  // Put all article list properties here. This regargds also state properties.

  @observable collection = [];

  modifiedCollection = [];

  @observable current = {};

  @computed get hasCurrent() {
    return !!this.current.id
  }

  @observable total = 0;

  @observable filterTotal = 0;

  @observable filterMeta = {};

  @observable page = 1;

  // This is defined in the backend.  Best if this is a multiple of 3 for the
  //  grid view in InfiniteList
  @observable limit = 21;

  @observable loading = false;

  hasMoreItems = true;

  @observable filter = {};

  @observable sorting = {};

  @observable selected = {};

  // Put all properties that are not to be observed here:
  // to see the store name when in production mode - Uglifyjs
  static storeName = 'ArticleStore';

  constructor(opts = {}) {
    super(opts)

    this.Model = Article

    this.initCommunicator()

  }

  /**
   * Returns the current ediitable version. So even if the `current` property
   * of this store points to an older version, the original article is returned.
   */
  @computed get currentEditable() {
    return this.current.origin || this.current
  }

  // @overload({ id: 'string?', opts: 'object?', data: 'object?' })
  /**
   * When @Pagination is included, limit and page will be updated in opts if no id and not defined
   * @Pagination will also add +1 to the page or this.page, by design
   * When @filterable is included: opts.params.filter will default to this.filter if not defined
   * Filtered collection needs to shadow the normal collection when no filter
   */
  load(id = null, opts = {}, data = null) {
    this.loading = true
    return this.dispatch('get', {
      path: `article/${id}`
    }).then((response) => {

      if (!response || !response.body || response.error) {
        throw new Error('error in article get')
      }

      const newModel = addToCollection(this, this.collection, response.body.data, Article)
      this.loading = false
      return newModel
    })
  }

  modifiedLoad(id = null, opts = {}) {
    this.loading = true
    const filter = toJS(this.filter) || {}
    const sorting = toJS(this.sorting) || {}
    const params = Object.assign({}, opts.params)
    params.filter = JSON.stringify(filter)
    params.sorting = JSON.stringify(sorting)

    return this.dispatch('get', {
      path: 'article',
      params
    }).then((response) => {

      if (!response || !response.body || response.error) {
        throw new Error('error in article get')
      }
      // add models to collection (as an array)
      let responseArray = Array.isArray(response.body.data) ? response.body.data : [response.body.data]
      responseArray = responseArray.map((articleRaw) => {
        const modelRef = addToCollection(this, this.collection, articleRaw, Article)
        if (deepGet(opts, 'params.filter') !== []) {
          addToCollection(this, this.modifiedCollection, modelRef, Article)
        }
        return modelRef
      })

      // if only a sort is used, the filter is still set but empty, so this check
      //   below really supports both (as needed)
      const filterSet = deepGet(response.body, 'meta.filter')
      if (filterSet && filterSet !== {}) {
        this.filterTotal
          = deepGet(response.body, 'meta.pagination.total') || 0
        this.filterMeta = deepGet(response.body, 'meta.filter') || {}
      }
      else {
        this.total = deepGet(response.body, 'total') || 1
      }
      this.loading = false

      return { data: responseArray, meta: response.body.meta }
    })
  }

  /**
   * Gets an article and sets to Current
   * @returns local or remote Article Model
   */
  openById(id, opts = {}) {
    if (this.current && this.current.id === id * 1 && !opts.force) {
      console.warn('requested article is already set to current')
      throw new Error('article is already loaded and set as current')
    }

    const localModel = this.getById(id)
    if (!opts.force && localModel) {
      this.setCurrent(localModel)
      return new Promise((resolve) => {
        resolve(localModel)
      })
    }

    return this.load(id, opts)
      .then((model) => {
        this.setCurrent(model)
        return model
      })
  }

  createVersion(article) {
    return article.versionStore.create().then(() => {
      // No article model returned after save
      // Manual adjustment
      article.versionNumber += 1
    })
  }

  restoreVersion(article, versionId) {
    return article.versionStore.restore(versionId).then((versionData) => {
      versionData.id = article.id
      versionData.instanceVersion = -1

      const restoredArticle = this.createModel(versionData)
      restoredArticle.release()
      article.origin = restoredArticle
    })
  }

  loadVersion(
    versionId = Article.INVALID_INSTANCE_VERSION,
    opts = {},
    data = null
  ) {
    if (!(this.current.id && this.current.versionStore)) {
      throw new Error('Loading versions require a propert version id')
    }

    const origin = this.current.origin || this.current

    if (versionId === Article.INVALID_INSTANCE_VERSION) {
      if (origin) {
        origin.versionStore.collection = this.current.versionStore.collection
        this.setCurrent(origin)
      }
      return Promise.resolve()
    }

    const currentVersionStore = this.current.versionStore

    return currentVersionStore.load(versionId, opts, data).then((result) => {
      // Taking the already built model from result
      // Could also use result.body and create a new version model of it - the data is the same
      const desiredVersion = result
      desiredVersion.origin = origin
      desiredVersion.origin.versionStore.collection = this.current.versionStore.collection
      desiredVersion.instanceVersion = versionId
      this.openFromVersion(desiredVersion)
    })
  }

  save(model, opts = {}) {
    const { pageStore } = CircularDependencyFreeStore

    if (!model) {
      console.error('saving but invalid model for saving')
      return Promise.reject(new Error('Model data invalid'))
    }

    return this.dispatch('put', {
      path: `article/${model.id}`,
      data: model.getJSON()
    }).then((result) => {
      let updatedModel = model
      if (!opts.noBackendSet) {
        // model is processed by the backend, so it it necessary to rebuild the model afterwards
        updatedModel = addToCollection(this, this.collection, result.body.data, Article)
      }
      else {
        // Set values we need calculated from the backend and never set by the frontend
        const existingModel = this.getById(updatedModel.id * 1)
        if (existingModel) {
          existingModel.mandatoryFieldsMissing = deepGet(result, 'body.data.mandatoryFieldsMissing')
        }
      }
      // - setting the page.hasChanged flag after article on that page changes
      if (
        pageStore
        && pageStore.hasCurrent
        && pageStore.current.hasItem(model.id)
      ) {
        pageStore.current.hasChanged = 1
      }
      return updatedModel
    }).catch((err) => {
      throw err
    })
  }

  destroy(article) {
    if (article && article.id) {
      return this.dispatch('del', {
        path: `article/${article.id}`,
      })
    }

    // if not an article, throw error
    throw new Error('missing article in delete action')
  }

  reset(opts) {
    // if (this.loading) {
    //   return
    // }

    const vals = {
      ...defaultVals,
    }

    delete vals.filter
    delete vals.sorting

    if (!('filter' in opts || 'sorting' in opts)) {
      delete vals.collection
      delete vals.modifiedCollection
    }

    const resetCollection = !!vals.collection
    delete vals.collection

    // This will overwrite/reset everything that hasen't been removed from vals
    Object.assign(this, {
      ...vals,
      ...opts,
    })

    if (resetCollection) {
      if ('filter' in opts || 'sorting' in opts) {
        this.modifiedCollection.splice(0, this.modifiedCollection.length)
      }
      else {
        this.collection.splice(0, this.collection.length)
        // operates as a child to collection
        this.modifiedCollection.splice(0, this.modifiedCollection.length)
      }

      this.page = 0
    }

    return this.load()
  }

  canLoad() {
    return !this.loading && this.hasMoreItems
  }

  openFromVersion(version) {
    const articleData = version.getArticleData(version.origin.id)

    articleData.type = 'version'
    articleData.versions = version.origin.versionStore.toJSON()

    const versionArticleModel = this.createModel(articleData, {
      autoSave: false,
      autoSavable: false,
    })
    versionArticleModel.origin = version.origin
    this.setCurrent(versionArticleModel)
  }

  buildOutDataForCreation(data = {}, opts = {}) {
    const createdIso = data.createdIso || uiStore.contentLanguage

    const { projectStore } = CircularDependencyFreeStore

    if (!(projectStore && projectStore.hasCurrent)) {
      throw new Error(
        'ArticleStore#create(): A projectStore with a current '
          + 'project is inevtable to create a new article in order to determine the '
          + 'proper channel or projectId!'
      )
    }

    const namePrefix = formatMessage('article.name-default')

    data = {
      templateId: data.templateId,
      ...i18n({}, 'name', createdIso, `${namePrefix} (${data.templateName})`),
      channels: [projectStore.current.channelShortcut],
      placeholder: {
        [projectStore.current.channelShortcut]: [],
      },
      createdIso,
      projectIds: [projectStore.current.id],
      editorVersion: data.editorVersion,
    }

    return data
  }

  create(data = {}, opts = {}) {
    data = this.buildOutDataForCreation(data, opts)

    const newModel = new Article(this, data)
    return this.dispatch('post', {
      path: 'article',
      data: newModel.getJSON()
    }).then((response) => {
      if (deepGet(response, 'body.data') && !response.error) {
        const newArticle = addToCollection(this, this.collection, response.body.data, Article)
        if (deepGet(opts, 'params.filter') !== []) {
          this.modifiedCollection.push(newArticle)
        }
        return newArticle
      }
      throw new Error('failed to create new Article')
    })
  }

  editArticle(articleId) {
    console.log(articleId)
  }

  @action
  actionDestroyItem(itemId) {
    const item = this.getById(itemId)

    if (!item) {
      // eslint-disable-next-line max-len
      throw new Error(
        `Could not find \`${this.Model.constructor.name}\` item with id \`${itemId}\`!`
      )
    }

    const isCurrent = this.current === item

    item.destroy()

    if (isCurrent) {
      this.setCurrent(null)
    }
  }

  /**
   * @param id: id of the article to clone
   * @param lang: list of language to clone the article to
   * @param toOpen (optional): the langIso of the article to open after creation
   * @param projectId: the current projectId
   */
  createArticlesInLanguage(id, lang = [], toOpen, projectId) {
    // Check if the projectId is in the current articles projectIds
    if (
      useProjectFilter
      && this.current.projectIds.length
      && !this.current.projectIds.find(projId => projId === projectId)
    ) {
      alert('URL MANIPULATED. NO ARTICLES WILL BE CREATED')
      throw new Error('URL MANIPULATED. NO ARTICLES WILL BE CREATED')
    }

    // create a block of data that is the urls
    // form: POST: article/{id}/{lang}
    const dataBlock = []
    for (let i = 0; i < lang.length; i++) {
      dataBlock[i] = {
        route: `article/${id}/${lang[i]}`,
        data: {
          route: null,
          projectId,
        }
      }
    }

    // call api with article/:id/copy
    return this.dispatch('post', {
      path: 'batch',
      data: {
        jobs: dataBlock
      }
    }).then((result) => {
      // batch command. last has the most complete info set
      const updatedLanguages = result.body[result.body.length - 1].body.data.languages

      // This will update the language arrays of all related articles
      // They may be cached in the collection already and therefore would not have
      // any changes in their language array until reload.
      updatedLanguages.forEach((tmpLang) => {
        const articleId = tmpLang[Object.keys(tmpLang)]
        const tmpArticle = this.getById(articleId)
        if (tmpArticle) {
          tmpArticle.languages = updatedLanguages
        }
      })

      if (toOpen) {
        const tmp = updatedLanguages.find(el => el.createdIso === toOpen) || {}
        return tmp.id
      }

      // Need to verify current model (and thus the dropdown) have the new set of languages
      if (this.getById(id).languages.length !== updatedLanguages.length) {
        this.load(id, { force: true })
      }

      return 0 // not found or not switching articles
    })
  }

  /**
   * Creates a duplicate of an article in the same language
   * @param {*} article - item to be copied
   * @param {*} data - specific data if api is extended
   * @param {*} opts - cooresponds to config/index route: 'copy'
   */
  copyArticle(article, data) {
    if (!article) {
      return console.warn('no article passed to be copied')
    }

    // call api with article/:id/copy
    return this.dispatch('post', {
      path: `article/${article.id}/copy`,
      content: data
    }).then((result) => {
      if (!result.body || !result.body.data) {
        return console.warn('no article was created in copy action')
      }

      return addToCollection(this, this.collection, result.body.data, Article)
    }).catch((err) => {
      console.log(err.message)
      throw err
    })
  }

  getArticleUsage(articleId) {
    return this.dispatch('get', {
      path: `article/${articleId}/usage`
    }).then((result) => {
      if (!result.body || !result.body.data) {
        return console.warn('no article was created in copy action')
      }

      return result.body.data
    }).catch((err) => {
      console.log(err.message)
      throw err
    })
  }
}
