
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { FormattedMessage } from '../../translations'
import Icon from '../../shared/icons'
import GenevaButton from '../../ui/components/GenevaButton'
import uploadDialog from '../../shared/containers/UploadDialog'
import { metaTags as metaTagsDialog } from '../containers/dialogs'
@observer
export default class ImageCMToolbar extends Component {

  static propTypes = {
    context: PropTypes.object,
    className: PropTypes.string,
    onEditImage: PropTypes.func.isRequired,
    onChangeImage: PropTypes.func.isRequired,
    onRemoveImage: PropTypes.func.isRequired,
    onErrorImage: PropTypes.func.isRequired,
    onAddMetaTags: PropTypes.func.isRequired,
    getImageDetails: PropTypes.func.isRequired,
    onImportFromMediaManager: PropTypes.func,
    onImportFromBynder: PropTypes.func,
    article: PropTypes.object
  }

  @autobind
  handleClickEditImage() {
    if (this.props.onEditImage) {
      this.props.onEditImage()
    }
  }

  @autobind
  handlePreventFocus(event) {
    event.nativEvent.preventDefault()
  }

  @autobind
  handleImageChange(imageUrlOrFiles: string|Array) {
    if (this.props.onChangeImage) {
      this.props.onChangeImage(imageUrlOrFiles)
    }
  }

  @autobind
  handleRemoveImage() {
    if (this.props.onRemoveImage) {
      this.props.onRemoveImage()
    }
  }

  @autobind
  handleMetaTags() {
    const currentImage = this.props.getImageDetails()

    metaTagsDialog({
      titleText: currentImage.title || '',
      altText: currentImage.alt || ''
    }).then((result) => {
      if (this.props.onAddMetaTags
        && !(result.titleText === currentImage.title && result.altText === currentImage.alt)
      ) {
        this.props.onAddMetaTags({
          titleText: result.titleText,
          altText: result.altText
        })
      }
    })
  }

  @autobind
  handleFromMediamanager() {
    if (this.props.onImportFromMediaManager) {
      this.props.onImportFromMediaManager()
    }
  }

  @autobind
  handleFromBynder() {
    if (this.props.onImportFromBynder) {
      this.props.onImportFromBynder()
    }
  }

  @autobind
  handleError(err) {
    if (this.props.onErrorImage) {
      this.props.onErrorImage(err)
    }
  }

  @autobind
  handleImageUpload() {

    uploadDialog({
      config: {
        multiple: false
      },
      mediaType: 'image',
      handleChange: this.handleImageChange,
      handlePreventFocus: this.handlePreventFocus,
      handleError: this.handleError
    }).then((result) => {

      if (result.type && result.type === 'fromMediamanager') {
        // Replacing a single image using MediaManager
        this.handleFromMediamanager()
      }
      else if (result.type && result.type === 'fromBynder') {
        // Replacing a single image using Bynder
        this.handleFromBynder()
      }
      else {
        // Replacing a single image using URL
        this.handleImageChange(result.url)
      }
    })
  }

  render() {

    const hasValue = !!(this.props.context.target
      && this.props.context.target.value)

    return (<div
      className={classNames(this.props.className, 'toolbar', 'v-align')}
    >
      <span className="muted">
        <FormattedMessage id="image.image" />:&nbsp;
      </span>

      <GenevaButton className="small plain button"
        onClick={this.handleClickEditImage}
        disabled={!hasValue}
      >
        <FormattedMessage id="image.edit" />
      </GenevaButton>

      <GenevaButton className="small plain button"
        onClick={this.handleImageUpload}
      >
        { /* Upload should be possible with or without image! */ }
        <FormattedMessage id={!hasValue ? 'image.create' : 'image.replace'} />
      </GenevaButton>

      <GenevaButton className="small plain button"
        onClick={this.handleMetaTags}
        disabled={!hasValue}
      >
        <FormattedMessage id="image.meta-tags" />
      </GenevaButton>

      <span className="separator vertical"></span>

      <GenevaButton
        className="small plain button"
        onClick={this.handleRemoveImage}
        disabled={!hasValue}
      >
        <Icon name="ion-ios-trash-outline" />
      </GenevaButton>
    </div>)

  }

}
