import { createConfirmation } from 'react-confirm'
import ArticleLanguagesDialog from '../../components/dialogs/ArticleLanguages'

const confirm = createConfirmation(ArticleLanguagesDialog)


export default function (confirmation: String, options: Object = {}) {

  if (typeof confirmation !== 'string') {
    options = { ...confirmation }
    confirmation = ''
  }

  return confirm({ confirmation, options })
}
