import { store as uiStore } from '../../ui'
import Command from '../../shared/command'
import { Store } from '../../shared/store'
import { deepGet } from '../../shared/obj'
import { UserCanceledDialogError } from '../../shared/errors'
import { inspector } from '../../shared/decorators/inspector'
import { UploadImageCommand, UpdateImageBatchCommand, UploadImageBatch } from '.'

@inspector('saveImageEditingResult', 'image.saving')
export default class EditImageCommand extends Command {

  constructor(imageStore: Store, data: Object, opts: Object) {

    super(imageStore, data, opts)

    this.cachedFiles = []
    this.imageStore = imageStore
    this.data = this.parse(data)
    this.opts = opts

  }

  validate(imageStore: Store, data, opts) {
    if (!imageStore) {
      this.throwError('expects an imageStore passed to the command')
    }
    if (!Array.isArray(data.images)) {
      this.throwError('expects data passed to the command to contain and images array')
    }
    if (!data.channel || !(typeof data.channel === 'string')) {
      this.throwError('expects data passed to the command to contain a channel string')
    }
    if (!opts.params.lang || !(typeof opts.params.lang === 'string')) {
      this.throwError('expects opts passed to the command to include desired image lang')
    }
  }

  parse({ images, channel }) {

    images = images.map(({ constraints, image, size, multiple }) => {

      constraints = constraints || image.constraints

      const sourceType = image.file ? 'file' : 'url'

      // Cache the file. This is important, because the file object may get
      // lost whil editing and won't be returned from the editor. Thus
      // keeping a reference here is obligatory.
      this.cachedFiles.push({
        sourceType,
        image: sourceType in image
          ? image[sourceType]
          : null,
        imageId: image.id,
        multiple
      })

      // if any skip the preview, then all are of the same type
      if (image.url && !deepGet(image, 'skipPreviewUrl')) {
        image.value = this.imageStore.getPreviewURL(image.url)
      }

      delete image.file
      delete image.url

      return ({
        constraints,
        image,
        size
      })

    })

    return { images, channel }

  }


  exec() {
    // If just a single file given
    if (this.data.images.length === 1) {

      const image = deepGet(this, 'data.images.0')
      const isImageFitting = deepGet(image, 'image.fitting')

      // and the image fits perfectly into the placeholder
      if (isImageFitting) {
        const data = {
          status: 'saveImage',
          imageData: [{
            ...image,
            frame: {
              left: 0,
              top: 0,
              width: image.size.width,
              height: image.size.height
            },
          }]
        }
        // skip the imageEditor (cropper) and send the data directly to the api
        return this.treatImageEditingResult(data, this.opts)
      }
    }

    return uiStore.openDialog('imageEditor', {
      imageData: this.data.images
    })
      .then((data) => {
        if (data.status !== 'abort') {
          return this.treatImageEditingResult(data, this.opts)
        }
        throw new UserCanceledDialogError('edit image')
      })

  }


  treatImageEditingResult(data, opts) {

    if (data.status.indexOf('saveImage') > -1 && this.cachedFiles) {
      return this.saveImageEditingResult(data, opts)
    }

    return null

  }

  saveImageEditingResult(data, opts) {

    let ImageResultCommand = UpdateImageBatchCommand
    let commandData = data.imageData

    // restore cached files
    this.cachedFiles.forEach(({ sourceType, image }, i) => {
      if (image) {
        commandData[i][sourceType] = image
      }
    })

    // multiple url
    if (!this.cachedFiles[0].multiple && commandData.length > 1) {
      ImageResultCommand = UploadImageBatch
    }
    // Check if multiple images needs to be uploaded
    else if (!this.cachedFiles[0].multiple) {

      // Replace the upload command for single upload
      // In that case the image data set also has to be reduced to the first item
      ImageResultCommand = UploadImageCommand
      commandData = commandData[0]
    }

    commandData.channel = this.data.channel

    return new ImageResultCommand(this.imageStore, commandData, opts)
      .exec()

  }

}
