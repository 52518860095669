import { createConfirmation } from 'react-confirm'
import MetaTags from '../../components/dialogs/MetaTags'

const metaTags = createConfirmation(MetaTags)


export default function (confirmation, options = {}) {

  if (typeof confirmation !== 'string') {
    options = { ...confirmation }
    confirmation = ''
  }

  return metaTags({ confirmation, options })
}
