import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import { formatMessage } from '../../translations'

import HeaderWarning from '../../shared/components/HeaderWarning'
import { articleUsage as articleUsageDialog } from '../containers/dialogs'
import { deepGet } from '../../shared/obj'
import { dispatcher } from '../../shared/lib/command'


@dispatcher
@observer
class ArticleHeaderWarning extends Component {

  static propTypes = {
    article: PropTypes.object.isRequired,
    router: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
  }

  /**
   * Passed component for button dialog
  */
  @autobind
  handleArticleUsage() {

    const article = this.props.article.current

    articleUsageDialog({
      article
    }).then((dialogResult) => {
      this.context.dispatch(
        this.props.commands.EditItemCommand,
        {
          router: this.props.router,
          type: 'page',
          ...dialogResult
        }
      )
    }).catch((err) => {
      // Catch the cancel event from the dialog
      console.log(err)
    })
  }

  render() {

    const {
      article
    } = this.props

    const usage = deepGet(article, 'current.usage') || []
    const count = usage.length
    const displayText = formatMessage({ id: 'article.warning.text' }, { count })

    const isUsedMoreThanOnce = count > 1

    return (
      <HeaderWarning
        displayText={displayText}
        externalComponent={this.handleArticleUsage}
        externalComponentText={formatMessage({ id: 'article.warning.button' })}
        show={isUsedMoreThanOnce}
      />)

  }
}

export default ArticleHeaderWarning
