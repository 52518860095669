import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observable, action } from 'mobx'
import { observer } from 'mobx-react'
import { autobind } from 'core-decorators'
import { confirmable } from 'react-confirm'
import PerfectScrollbar from 'perfect-scrollbar'
import { formatMessage, FormattedMessage } from '../../../translations'

import { Dialog } from '../../../shared/components/Dialog'
import { Separator } from '../../../shared/components/Dropdown'
import ContentLoadingBox from '../../../shared/components/ContentLoadingBox'
import FilterPageCommand from '../../../shared/commands/filter-page'
import MissingAccessDialog from '../../../shared/components/Dialog/MissingAccessDialog'
import GenevaButton from '../../../ui/components/GenevaButton'
import ListEntry from './ListEntry'
import { store as articleStore } from '../..'

const css = /* typeof window === 'undefined' ? {} : */require('../../styles.scss')


@confirmable
@observer
class ArticleUsage extends Component {

  static propTypes = {
    article: PropTypes.object,
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,
    options: PropTypes.shape({
      article: PropTypes.object,
    })
  }

  /* eslint-disable react/sort-comp */
  @observable pageList = []

  @observable loadingItems = false

  @observable ps = null
  /* eslint-enable react/sort-comp */

  @action
  addPages(pages) {
    this.pageList = pages
  }

  constructor(props) {
    super(props)
    this.state = {
      showMissingAccessDialog: false,
      loadingFallback: true
    }
  }

  componentDidMount() {

    const article = this.props.options.article
    this.loadingItems = true

    this.loadPageList(article)
    this.loadProjectFallback(article)
  }

  loadProjectFallback(article) {
    articleStore.getArticleUsage(article.id)
      .then((results) => {
        this.fallbackProjectInfo = results
        this.setState({
          loadingFallback: false
        })
      }).catch((err) => {
        this.setState({
          loadingFallback: false
        })
        throw err
      })
  }

  loadPageList(article) {

    new FilterPageCommand('id', article.usage, 'parentPages').exec()
      .then((result) => {
        this.addPages(result)
        this.loadingItems = false
      })
      .catch((err) => {
        this.loadingItems = false
        throw err
      })
  }

  @autobind
  setScrollContainer() {
    this.ps = new PerfectScrollbar('#articleUsage-container', {
      suppressScrollX: true
    })
  }

  @autobind
  getFocus() {
    return this.focus
  }

  @autobind
  handleRef(el) {
    if (el !== null) {
      this.focus = el
    }
  }

  @autobind
  handleLinkClick({ target }) {
    // TODO: Put multi-user unlocking inside edit-item command, when leaving CM
    // Release the locked article

    this.props.options.article.store.unsetCurrent()
    this.props.proceed({
      id: target.dataset.pageId,
      projectId: target.dataset.projectId
    })
  }

  @autobind
  handleMissingRightsClick(e) {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    this.setState({
      showMissingAccessDialog: true
    })
  }

  @autobind
  handleCommit(e) {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    this.props.dismiss()
  }

  @autobind
  handleHideDialog() {
    this.setState({
      showMissingAccessDialog: false
    })
  }

  renderLoader() {

    // if load completed and no items in usage, meaning it's not actively in any page
    if (!this.loadingItems && !this.loadingFallback) {
      return (<div className="error-info">
        <FormattedMessage id="article-usage-dialog.unused" />
      </div>)
    }

    return (<ContentLoadingBox
      spinner
      message={{
        id: 'placeholder-empty'
      }}
    />)
  }

  @autobind
  renderMissingAccessDialog() {
    return (
      <MissingAccessDialog
        handleHideDialog={this.handleHideDialog}
      >
      </MissingAccessDialog>
    )
  }

  renderItems() {
    return (this.pageList.map(page => (
      <ListEntry
        key={page.id}
        page={page}
        fallbackProjectInfo={this.fallbackProjectInfo}
        handleLinkClick={this.handleLinkClick}
        handleMissingRightsClick={this.handleMissingRightsClick}
      />
    )))
  }

  @autobind
  renderFooter() {
    return (
      <div className="grid-content text-right">
        <GenevaButton
          type="submit"
          className="small primary button"
          onClick={this.handleCommit}
          ref={this.handleRef}
        >
          <FormattedMessage id="article-usage-dialog.close" />
        </GenevaButton>
      </div>
    )
  }

  @autobind
  renderArticleUsageDialog() {
    const usage = this.props.options.article.usage || {}
    return (
      <Dialog
        className={css.articleUsage}
        overlayClassName={css.tableDialogOverlay}
        isOpen={this.props.show}
        onRequestClose={this.handleRequestClose}
        onCancel={this.handleCommit}
        onAfterOpen={this.setScrollContainer}
        renderFooter={this.renderFooter}
        title={formatMessage({ id: 'article-usage-dialog.title' }, { count: usage.length })}
        focusEl={this.getFocus}
      >
        <div className="grid-content form-main">

          <div className="list-header">
            <FormattedMessage id="article-usage-dialog.list-header" />
          </div>

          <Separator />
          <div className={css.articleUsageContainer} id="articleUsage-container">
            {
              this.pageList.length < 1 || this.state.loadingFallback
                ? this.renderLoader()
                : this.renderItems()
            }
          </div>
        </div>
      </Dialog>
    )
  }

  render() {
    if (this.ps) {
      this.ps.update()
    }

    return (
      <React.Fragment>
        {
          this.state.showMissingAccessDialog
            ? this.renderMissingAccessDialog()
            : this.renderArticleUsageDialog()
        }
      </React.Fragment>
    )
  }
}

export default ArticleUsage
