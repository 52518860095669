import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { formatMessage } from '../../translations'

// eslint-disable-next-line max-len
import InsertImageIntoArticlePlaceholderCommand from '../commands/insert-image-into-article-placeholder'
// eslint-disable-next-line max-len
import RemoveImageFromArticlePlaceholderCommand from '../commands/remove-image-from-article-placeholder'
import AddMetaTagsToImage from '../commands/add-meta-tags-to-image'
// eslint-disable-next-line max-len
import EditImageInArticlePlaceholderCommand from '../commands/edit-image-in-article-placeholder'

import PropagateImageCommand from '../../image/commands/propagate-image'

import cancelable from '../decorators/cancelable-promise'
import { imagePropagation } from '../../image/containers/dialogs'
import handleDialogError from '../components/Dialog/handleDialogError'

import { store as imageStore } from '../../image'
import { store as articleStore } from '../../article'
import { store as uiStore } from '../../ui'
import { store as authStore } from '../../auth'
import { store as projectStore } from '../../project'

import cacheImage from '../../image/cacheImage'

import alert from '../components/Alert'
import { hasPermission } from '../utils/user-rights'

export default function connectImageToolsToCommands(Target) {
  @cancelable
  class ConnectedTarget extends Component {
    static propTypes = {
      value: PropTypes.string,
      onBeforeChangeImage: PropTypes.func,
      onBeforeChangeVideo: PropTypes.func,
      context: PropTypes.object.isRequired,
      spec: PropTypes.object,
      onBeforeItemChange: PropTypes.func,
    };

    constructor(props) {
      super(props)
      this.state = {
        value: props.value,
      }

      if (!this.cancelables) {
        this.cancelables = []
      }
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.value !== this.state.value) {
        const newState = {
          value: nextProps.value,
          ...(!nextProps.value ? { file: null, url: '' } : {}),
        }

        this.setState(newState)
      }
    }

    @autobind
    getImageDataFromContext() {
      this.props.context.assert('image')

      const { target, type } = this.props.context
      let source = null

      // Check for external source
      if (target.dropData) {
        source = JSON.parse(target.dropData)

        // If nested
        if (source.source) {
          source = source.source
        }
      }

      return {
        value: target.value,
        file: target.file,
        url: target.url,
        mimeType: target.mimeType,
        type,
        constraints: target.constraints,
        imageData: target.imageData,
        source,
      }
    }

    getPlaceholderDataFromContext() {
      const { target, type, sourceType } = this.props.context

      return {
        sourceType,
        type,
        article: target.article,
        pid: target.pid,
        multiple: target.multiple,
      }
    }

    // Building information for mediaManager
    getImageSpecForImageManager(target) {
      const projectId = this.props.context.getCurrentIds().projectId
      const project = projectStore.collection.find(el => el.id === projectId)

      // Legacy data
      return {
        imageSpec: {
          constraints: {
            [target.article.channel]: {
              dest: {
                w: target.constraints.minWidth,
                h: target.constraints.minHeight,
              },
            },
          },
        },
        multiple: target.multiple,
        maxImages: target.maxImages,
        lang: authStore.user && authStore.user.guiIso,
        projectId,
        projectName: project && project.name,
        type: target.type,
      }
    }

    @autobind
    handleEditImage() {
      const currImage = this.props.context.target.article.getDataInPlaceholder(
        this.props.context.target.pid
      )

      const cachedImage = cacheImage(currImage, `${new Date().getTime()}-old`)

      return new Promise((resolve, reject) => {
        const imageData = this.getImageDataFromContext()
        const placeholderData = this.getPlaceholderDataFromContext()

        const cmd = new EditImageInArticlePlaceholderCommand(
          imageData,
          placeholderData
        )

        this.subscribeToCommandEvents(cmd, { editing: true })

        return cmd
          .exec()
          .then(() => {
            this.handleInitialImagePropagation(currImage, cachedImage)
            resolve()
          })
          .catch(ex => handleDialogError(ex, reject))
      })
    }

    @autobind
    handleImagePropagation(url) {
      const placeholderData = this.getPlaceholderDataFromContext()
      const currImage = placeholderData.article.getDataInPlaceholder(
        placeholderData.pid
      )
      const opts = { params: { lang: placeholderData.article.createdIso } }

      const cmd = new PropagateImageCommand(
        imageStore,
        currImage.id,
        { url, article: placeholderData.article, pid: placeholderData.pid },
        opts
      )

      this.subscribeToCommandEvents(cmd)

      cmd.exec()
    }

    @autobind
    handleInitialImagePropagation(currImage, cachedImage) {
      // Skip the propagation when handling multiple images
      // or no images passed in at all
      const placeholderData = this.getPlaceholderDataFromContext()
      if (!currImage || !cacheImage || placeholderData.multiple) {
        return
      }

      const userAllowed = hasPermission([
        'replaceImageLanguageVariants'
      ])

      // verify there are pages to send the image(s) to and user has the rights
      if (articleStore.current.languages.length > 1 && userAllowed) {
        imagePropagation({
          currImage,
          cachedImage,
          realURL: this.props.value,
          channel: articleStore.current.channel,
        }).then((results) => {
          imageStore.languageReferences = results.languageReferences
          this.handleImagePropagation(results.url)
        })
      }
    }

    @autobind
    handleReplaceImage(opts) {
      return new Promise((resolve, reject) => {
        const imageData = this.getImageDataFromContext()
        const placeholderData = this.getPlaceholderDataFromContext()

        // TODO refactor
        // Multiple urls
        if (!imageData.value) {
          imageData.value = imageData.imageData[0].value
          imageData.url = imageData.imageData[0].url
        }

        const cmd = new InsertImageIntoArticlePlaceholderCommand(
          imageData,
          placeholderData,
          opts
        )

        this.subscribeToCommandEvents(cmd)

        const promise = cmd.exec()

        this.makeCancelable(promise)
          .then(() => {
            resolve()
          })
          .catch((err) => {
            if (this.isCanceledPromise(err)) {
              reject(err)
            }

            this.setState({
              value: '',
              sourceType: null,
              file: null,
              url: '',
            })
            reject()
          })
      })
    }

    @autobind
    handleRemoveImage() {
      this.props.context.assert('image')

      const { target } = this.props.context

      new RemoveImageFromArticlePlaceholderCommand({
        pid: target.pid,
        article: target.article,
      })
        .exec()
        .then(() => {
          // Recreate the context but now without any value as that's been just
          // removed.
          this.props.context.createFromElement({
            ...target,
            // This should always return null, otherwise something must have went
            // wrong.
            // We still use this call instead of just setting it to null to ensure
            // we are consistent.
            value: target.article.phAccessor.get(target.pid),
          })
        })

      this.setState({
        value: '',
        sourceType: null,
        file: null,
        url: '',
      })
    }

    @autobind
    handleImportFromMediaManager() {
      const { target } = this.props.context
      const spec = this.getImageSpecForImageManager(target)

      return uiStore.openDialog('mediaManager', spec).then((data) => {
        if (data.status === 'committed') {
          this.handleImageChange(data.result)
        }
      })
    }

    @autobind
    handleImportFromBynder() {
      const { target } = this.props.context
      const spec = this.getImageSpecForImageManager(target)

      return uiStore.openDialog('bynder', spec)
        .then((data) => {
          if (data.status === 'committed') {
            this.handleImageChange(data.result)
          }
        })
    }

    @autobind
    handleAddMetaTags(metaTags = {}) {
      this.props.context.assert('image')

      const { target } = this.props.context
      const currImage = target.article.getDataInPlaceholder(target.pid)
      const lang = target.article.createdIso

      return new AddMetaTagsToImage({
        pid: target.pid,
        article: target.article,
        lang,
        imageId: currImage.id,
        metaTags,
      })
        .exec()
        .then((data) => {
          target.article.placeDataInPlaceholder('image', target.pid, data)
        })
    }

    @autobind
    getImageDetails() {
      this.props.context.assert('image')

      const { target } = this.props.context
      const currImage = target.article.getDataInPlaceholder(target.pid)
      return currImage
    }

    @autobind
    handleImageChange(input, dropData) {
      const { context, onBeforeItemChange } = this.props

      let cachedImage = null

      if (onBeforeItemChange) {
        onBeforeItemChange(input)
      }
      // in this case cache the current image to display it again after
      // it was swapped out
      else {
        const imageToBeCached = context.target.article.getDataInPlaceholder(
          context.target.pid
        )

        if (imageToBeCached) {
          cachedImage = cacheImage(
            imageToBeCached,
            `${new Date().getTime()}-old`
          )
        }
      }

      return new Promise((resolve, reject) => {
        // waiting for possible refocusing in the
        // onBeforeChangeImage handler
        setTimeout(() => {
          let ok = false
          let imageData

          // single url
          if (typeof input === 'string') {
            ok = true
            imageData = this.collectImageDataFromUrl(input)
            if (dropData) {
              imageData.dropData = dropData
            }
          }
          // single url with options
          else if (input && input.src && typeof input.src === 'string') {
            ok = true
            imageData = input
            const tempData = this.collectImageDataFromUrl(input.src)
            Object.assign(imageData, tempData)
          }

          // batch support
          else if (Array.isArray(input)) {
            // single file
            if (input[0].file && input[0].file instanceof File) {
              imageData = this.collectImageDataFromFile(input)
              ok = !!imageData.file
            }
            // multiple files
            else if (input[0] instanceof Blob) {
              imageData = this.collectImageDataFromBlob(input)
              ok = !!imageData.file
            }
            // multiple urls
            else if (typeof input[0] === 'string') {
              imageData = []
              input.forEach((url) => {
                imageData.push(this.collectImageDataFromUrl(url))
              })
              ok = imageData.length === input.length
              imageData = { imageData }
            }
            // multiple urls with options
            else if (input[0] && input[0].src && typeof input[0].src === 'string') {
              imageData = []
              input.forEach((imageObj) => {
                const tempData = this.collectImageDataFromUrl(imageObj.src)
                imageData.push(Object.assign(imageObj, tempData))
              })
              ok = imageData.length === input.length
              imageData = { imageData }
            }
          }

          this.setState(imageData)

          const { target } = this.props.context

          if (ok) {
            // ensure the context
            this.props.context.createFromElement({
              constraints: target.constraints,
              pid: target.pid,
              article: target.article,
              mimeType: target.mimeType,
              multiple: target.multiple,
              type: 'image', // specified for images
              ...imageData,
            })

            // In case of replacing an image
            // pass on the id from the existing image in the placeholder
            let id
            const previousPlaceholderData = target.article.getDataInPlaceholder(
              target.pid
            )
            if (previousPlaceholderData) {
              id = previousPlaceholderData.id
            }

            this.handleReplaceImage({ id })
              .then(() => {
                let currImage = target.article.getDataInPlaceholder(target.pid)

                if (!cachedImage) {
                  const placeholderData = this.getPlaceholderDataFromContext()
                  currImage = placeholderData.article.getDataInPlaceholder(
                    placeholderData.pid
                  )
                }

                this.handleInitialImagePropagation(currImage, cachedImage)

                resolve()
              })
              .catch(() => {
                reject()
              })
          }
        }, 1)
      })
    }

    @autobind
    handleError(err) {
      const names = err.names ? err.names.join(', ') : ''

      alert(
        formatMessage({ id: err.message }, { names }),
        formatMessage({ id: `${err.message}-title` })
      )
    }

    subscribeToCommandEvents(command, opts) {
      const { spec, context } = this.props

      const width = spec ? spec.minWidth : context.target.constraints.minWidth

      const height = spec
        ? spec.minHeight
        : context.target.constraints.minHeight

      // Take width or height as message id
      let alertMessageId = width
        ? 'image.constraint-error-width'
        : 'image.constraint-error-height'

      // Change to both if needed
      if (width && height) {
        alertMessageId = 'image.constraint-error'
      }

      // Special message for gif, since width and height needs to be exactly the same
      if ((context.target.file && context.target.file.type === 'image/gif')
        || (context.target.url && context.target.url.match(/\.(gif)/))) {
        alertMessageId = 'image.constraint-error-gif'
      }

      let subscription = command.on('error:constraint-failure', (data) => {
        alert(
          formatMessage(
            { id: alertMessageId },
            {
              count: data.count,
              width,
              height,
            }
          ),
          formatMessage({ id: 'image.constraint-error-title' })
        )
      })
      subscription = command.on('error:editing-gif', (data) => {
        alert(
          formatMessage(
            { id: 'image.editing-error-gif' }
          ),
          formatMessage({ id: 'image.constraint-error-title' })
        )
      })
      subscription = command.on('error:invalid-url', (data) => {
        alert(
          formatMessage(
            { id: 'image.url-invalid' },
            {
              url: data.url,
            }
          ),
          formatMessage({ id: 'image.url-invalid-title' })
        )
      })

      subscription = command.on('error:svg-error', (data) => {
        alert(
          formatMessage(
            { id: 'image.svg-error' },
          ),
          formatMessage({ id: 'image.svg-error-title' })
        )
      })

      this.cancelables.push(subscription)
    }

    collectImageDataFromFile(files) {
      let file
      let value
      const sourceType = 'file'

      files = this.validateFiles(files)

      if (files.length) {
        file = files[0].file
        value = files[0].content
      }

      return {
        file,
        value,
        sourceType,
      }
    }

    collectImageDataFromBlob(blobs) {
      let file
      let value
      const sourceType = 'file'

      blobs = this.validateBlobs(blobs)

      if (blobs.length) {
        file = blobs[0]
        value = blobs[0].name
      }

      return {
        file,
        value,
        sourceType,
      }
    }

    collectImageDataFromUrl(url) {
      return {
        url,
        value: url,
        sourceType: 'url',
      }
    }

    validateFiles(files = []) {
      const errors = []
      files = files.filter((file) => {
        if (!file.success) {
          errors.push(file)
        }

        return file.success
      })

      if (errors.length) {
        uiStore.addStatusInfo({
          data: {
            count: errors.length,
          },
          type: 'error',
          priority: 'high',
          id: 'file-error',
        })
        return null
      }

      return files
    }

    validateBlobs(blobs = []) {
      const errors = []
      blobs = blobs.filter((blob) => {
        if (!blob.success || blob.type !== 'application/zip') {
          errors.push(blob)
        }

        return blob.success
      })

      if (errors.length) {
        uiStore.addStatusInfo({
          data: {
            count: errors.length,
          },
          type: 'error',
          priority: 'high',
          id: 'file-error',
        })
        return null
      }

      return blobs
    }

    render() {

      return (<Target

        {...this.props}
        {...this.state}

        onEditImage={this.handleEditImage}
        onErrorImage={this.handleError}
        onImagePropagation={this.handleImagePropagation}
        onImportFromMediaManager={this.handleImportFromMediaManager}
        onImportFromBynder={this.handleImportFromBynder}
        onChangeImage={this.handleImageChange}
        onReplaceImage={this.handleReplaceImage}
        onRemoveImage={this.handleRemoveImage}
        onAddMetaTags={this.handleAddMetaTags}
        getImageDetails={this.getImageDetails}

      />)

    }
  }

  return ConnectedTarget
}
