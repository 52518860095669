import { Command } from '../lib/command'

function keyRequired(key, path = '') {
  if (path) {
    path = `.${path}`
  }
  // eslint-disable-next-line max-len
  return `requires \`data.${path}${key}\` (from \`contextStore.target.${key}\`) to be present`
}

/**
* @param {Object} router - router object
* @param {Object} data.type - what you are going to, article, page, other
* @param {Number} data.id - id of the desired loading item
* @param {String} data.path - url path without domain
* @param {Object} [data.langId] - is optional.  If nothing is passed, currently the
* project store will assume the current project
 */
export default class EditItemCommand extends Command {

  static storeRequirements = {
    project: true,
    context: true
  }

  static validationSpec = {
    router: Command.ensureData.isPresent,
    'router.push': Command.ensureData.isFunction,
    type: Command.ensureData.oneOf(['article', 'widget', 'page', 'publish']),
    id: Command.ensureData.isNumber,
  }


  constructor(data: Object) {

    super(data)

    this.router = data.router
    this.data.type = data.type

    // Negative ids could be possible for pagetreeroots. Fallback to default
    this.data.id = data.id < 1 ? 1 : data.id

    this.urlParts = this.parse(this.data, this.currentPath())

  }

  validate(data) {

    return ['id', 'type']
      .filter(key => !(key in data) || !data[key])
      .map(key => keyRequired(key))
      .join(', ')

  }

  parse(data, pathLocation) {
    const parts = {}

    parts.publish = pathLocation.match(/\/(publish\/\d+)/g)
      ? pathLocation.match(/\/(publish\/\d+)/g).shift() : '/publish/1'
    parts.page = pathLocation.match(/\/(page)\/\d+/g)
      ? pathLocation.match(/\/(page)\/\d+/g).shift() : '/page/1'
    parts.article = pathLocation.match(/\/(article)\/\d+/g)
      ? pathLocation.match(/\/(article)\/\d+/g).shift() : null // '/article/1'
    parts.widget = pathLocation.match(/\/(widget)\/\d+/g)
      ? pathLocation.match(/\/(widget)\/\d+/g).shift() : null // '/widget/1'

    parts[data.type] = `/${data.type}/${data.id}`

    return parts
  }

  currentPath() {
    return this.data.path || window.location.pathname
  }

  getIdsForContext() {
    const tmp = {}

    Object.keys(this.urlParts)
      .forEach((key) => {
        if (this.urlParts[key]) {
        // todo: since 'project' got renamed to 'publish' to be able to push a project to the url
        // we have to adjust either
        // all the 'publish' references to 'project' or this 'project' here to 'publish'
          if (key === 'publish') {
            tmp.projectId = this.urlParts[key].match(/\d+/g).shift()
          }
          else {
            tmp[`${key}Id`] = this.urlParts[key].match(/\d+/g).shift()
          }
        }
      })

    return tmp
  }

  getUrlForItem() {
    switch (this.data.type) {
      case 'widget':
        return `${this.urlParts.publish}${this.urlParts.page}${this.urlParts.widget}/edit`
      case 'article':
        return `${this.urlParts.publish}${this.urlParts.page}${this.urlParts.article}/edit`
      case 'page':
        return `${this.urlParts.publish}${this.urlParts.page}`
      case 'publish':
        return `${this.urlParts.publish}`
      default:
        return `${this.urlParts.publish}${this.urlParts[this.data.type]}/edit`
    }
  }

  getCompleteItemUrl() {
    let url = this.getUrlForItem()
    // this is ~getCorrectProject, necessary for keeping the correct project id in the url
    const newProjectId = this.data.projectId
      || this.data.store.project.getSiblingProjectId(
        this.data.langId,
        this.data.currentProjectId
      )
    const newPageId = this.data.pageId

    if (newProjectId) {
      url = url.replace(
        /(publish\/\d+)/,
        `publish/${newProjectId}`
      )
    }

    if (newPageId) {
      url = url.replace(
        /(page\/\d+)/,
        `page/${newPageId}`
      )
    }

    return url
  }

  exec() {

    const url = this.getCompleteItemUrl()
    const { context } = this.data.store

    // tell the publish/containers/Container to allow a stateChanged
    // also allow change if the page or article/widget is on a different project
    if (this.data.type === 'publish' || context.currents.projectId * 1 !== this.data.projectId * 1) {
      this.data.store.project.calledToChangeCurrent = true
    }

    this.router.push(url)

    // reset the context to nothing after opening an item
    // and store the opened item ids
    context.setCurrentIds(this.getIdsForContext())

    // remove the context lock in case there is one.
    // when navigating to another page, the lock might be deadly.
    // the component adding the context lock needs to handle it
    context.unlockContext()
    context.createFromElement(null)

  }

}
